<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.source") }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table
        fixed-header
        height="calc(100vh - 275px)"
        class="table-padding-2-no-top"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th class="text-center" style="min-width: 100px">
                <SelectFilter
                  class="c-input-xs input-filter"
                  :options="typeOptions"
                  :label="$t('labels.type')"
                  :placeholder="$t('labels.type')"
                  name="type"
                  sort-name="type"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th class="text-center" style="min-width: 150px">
                <InputFilter
                  :label="$t('labels.name')"
                  :placeholder="$t('labels.name')"
                  name="name"
                  sort-name="name"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th class="text-center" style="width: 105px">
                <v-btn small color="primary" @click="addItem">
                  {{ $t("labels.add") }}
                </v-btn>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in items"
              :key="item.id"
              class="text-center"
            >
              <td>
                <v-autocomplete
                  class="c-input-small input-disabled-bold"
                  v-model="item.type"
                  :items="typeOptions"
                  :disabled="!item.editing || !!item.id"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-autocomplete>
              </td>
              <td class="">
                <v-text-field
                  v-model="item.name"
                  :disabled="!item.editing"
                  class="c-input-small input-disabled-bold"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-text-field>
              </td>
              <td class="text-center">
                <v-btn
                  v-if="!item.editing"
                  class="mr-1"
                  x-small
                  color="warning"
                  @click="toggleEditing(item, index, true)"
                  >{{ $t("labels.edit") }}</v-btn
                >
                <template v-else>
                  <v-btn
                    class="mr-1"
                    x-small
                    color="success"
                    @click="saveItem(item)"
                  >
                    {{ $t("labels.save") }}
                  </v-btn>
                  <v-btn
                    class="mr-1"
                    x-small
                    color="error"
                    @click="toggleEditing(item, index, false)"
                  >
                    {{ $t("labels.cancel") }}
                  </v-btn>
                </template>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { SOURCE_TYPES } from "@/libs/const";
import { debounce } from "lodash/function";

export default {
  name: "SourceDialog",
  components: {
    InputFilter: () => import("@/components/table/InputFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
  },
  props: {},
  data: () => ({
    items: [],
    isLoading: false,
    filters: {},
  }),
  computed: {
    typeOptions() {
      return [...SOURCE_TYPES].map((value) => ({
        value,
        text: this.$t(`labels.source_type_${value}`),
      }));
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    getList: debounce(function () {
      httpClient.post("/source-list", this.filters).then(({ data }) => {
        this.items = [...data].map((item) => ({
          ...item,
          editing: false,
        }));
        this.isLoading = false;
      });
    }, 500),

    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    addItem() {
      const item = {
        editing: true,
        id: null,
        type: null,
        name: null,
      };
      this.items.unshift(item);
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    async saveItem(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/source-save", item);
        this.isLoading = false;
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
        this.getList();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
